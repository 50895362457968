import React from "react"
import { Link } from "gatsby"

import facebook from "../images/Facebook.svg"
import medium from "../images/Medium.svg"
import mail from "../images/Mail.svg"
import twitter from "../images/Twitter.svg"

const Footer = () => {
  return(
    <footer>   
    
      <div className="social">
          <a href="https://fb.me/getsoundbiteapp" target="_blank" rel="noopener noreferrer">  
            <img src={facebook} alt="Logo which links to Soundbite's Facebook Page"></img>
          </a>

          <a href="https://medium.com/making-soundbite" target="_blank" rel="noopener noreferrer">
            <img src={medium} alt="Logo which links to Soundbite's Publication on Medium "></img>
          </a>

          <a href="mailto:darngoodstudio@gmail.com" target="_blank" rel="noopener noreferrer">  
            <img src={mail} alt="Logo which links to Soundbite's Email"></img>
          </a>  

          <a href="https://twitter.com/getsoundbiteapp" target="_blank" rel="noopener noreferrer">
            <img src={twitter} alt="Logo which links to Soundbite's Twitter Page"></img>
          </a>
          
      </div>
      
      <div class="legal">
        <Link to="/terms">Terms & Conditions</Link>
        <Link to="/privacy">Privacy Policy</Link>
      </div>
      
      <p id="copyright">Soundbite is a Darn Good product © {new Date().getFullYear()}</p>

    </footer>
)
}

export default Footer

